
.about-container {
  display: inline-block;
  border: 0;
}

.about-card {
  display: inline-block;
  font-size: 16px;
  text-align: left;
  line-height: 2rem;
  font-family: Surt-Light;
  border: white;
}

.about-title {
  font-family: Revelations-Italic;
  font-size: 60px;
  text-align: left;
  margin-bottom: 0.5px;
}

.about-title:hover {
  font-family: Apoc-Dark;
  color: #523472;
}


@media screen and (min-width: 1200px) {
  .about-container {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3rem 0 0;
  }

}