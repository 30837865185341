.projects-container {
  position: absolute;
}

.projects-container .project {
  display: inline-block;
  font-family: Surt-Light;
  padding: 2rem;
  font-size: 16px;
  text-align: left;
  position: relative;
  margin-left: 1.5rem;
  border: none;
}

.project .project-title {
  font-family: Revelations-Italic;
  font-size: 40px;
}

.projects-container .project .project-title:hover {
  font-family: Apoc-Dark;
  color: #523472;
}

.project a {
  display: inline-block;
  text-align: center;
  width: 5rem;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  background: #523472;
  position: relative;
  color: white;
  font-family: Surt-MediumOblique;
}

.project a:hover {
  color: yellow;
}