.skills-container {
  position: absolute;
}
.skills-container .skill-card {
  display: inline-block;
  position: relative;
  padding: 2rem;
  font-size: 16px;
  text-align: left;
  line-height: 1.5rem;
  font-family: Surt-Light;
  border: white;
}

.skill-card-title {
  font-family: Revelations-Italic;
  font-size: 30px;
}

.skill-card-title:hover {
  font-family: Apoc-Dark;
  color: #523472;
}

@media (min-width: 1200px) {
  .skills-container {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: left;
    padding: 0 3rem 0 0;
  }
}