

.logo {
  width: 200px;
  position: relative;
  color: white;
}


.navbar {
  background:white;
}


.nav-item a {
  color: black;
  font-family: Revelations-Italic;
  font-size: 40px;
  text-decoration: none;
  padding: 0 0 5px 0;
}

.nav-item a:hover {
  text-decoration: none;
  color: #523472;
  font-family: Apoc-Dark;
}

.nav-item a:active {
  text-decoration: none;
  color: #523472;
  font-family: Apoc-Dark;
}

@media screen and (min-width: 900px) {
  .nav-item {
    text-align: center;
  }

  .navbar-toggler {
    background: #523472;
  }
}