

.contacts-message {
  margin-top: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  font-family: Helvetica;
  font-size: 20px;
  text-align: left;

}
.contacts a {
  font-family: Revelations-Italic;
  font-size: 3rem;
  text-decoration: none;
  color: black;
}

.contacts a:hover {
  text-decoration: none;
  color: #523472;
  font-family: Apoc-Dark;
}

